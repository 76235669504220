import { IconComponent } from '@/lib/types'

export const OpenBookArchiveIcon: IconComponent = ({ ...properties }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...properties}
    >
      <path
        d="M5.25 2.5C4.83594 2.5 4.5 2.83594 4.5 3.25V4H2.75C2.33594 4 2 4.33594 2 4.75V20.25C2 20.6641 2.33594 21 2.75 21H21.25C21.6641 21 22 20.6641 22 20.25V4.75C22 4.33594 21.6641 4 21.25 4H19.5V3.25C19.5 2.83594 19.1641 2.5 18.75 2.5H15C13.7448 2.5 12.6823 3.16667 12 4.1224C11.3177 3.16667 10.2552 2.5 9 2.5H5.25ZM6 4H9C10.2526 4 11.25 4.9974 11.25 6.25V17.7656C10.6198 17.2891 9.84375 17 9 17H6V4.8724C6.01302 4.79167 6.01302 4.71094 6 4.63021V4ZM15 4H18V4.6276C17.987 4.70833 17.987 4.78906 18 4.86979V17H15C14.1562 17 13.3802 17.2891 12.75 17.7656V6.25C12.75 4.9974 13.7474 4 15 4ZM3.5 5.5H4.5V17.75C4.5 18.1641 4.83594 18.5 5.25 18.5H9C9.78646 18.5 10.4688 18.8984 10.8698 19.5H3.5V5.5ZM19.5 5.5H20.5V19.5H13.1302C13.5313 18.8984 14.2135 18.5 15 18.5H18.75C19.1641 18.5 19.5 18.1641 19.5 17.75V5.5Z"
        fill="currentColor"
      />
    </svg>
  )
}

OpenBookArchiveIcon.displayName = 'OpenBookArchiveIcon'
