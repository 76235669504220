import { GraphQLClient } from 'graphql-request'
import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from 'react'

import { useOIDCAuth } from '@/contexts/OidcAuthContext'
import { graphql } from '@/gql/generated'
import {
  ApplyTaskRefinementMutation,
  CreateMessageInput,
  CreateMessageMutation,
  CreateTaskInput,
  CreateTaskMutation,
  DeleteFileMutation,
  DeleteNotificationsMutation,
  DeleteTaskMutation,
  FetchActivityFlowDataQuery,
  FetchAllTasksQuery,
  FetchAllUsersQuery,
  FetchTasksByIdQuery,
  FetchViewerQuery,
  FetchViewerWithNotificationsQuery,
  FetchViewerWithTasksQuery,
  GetThreadEventsQuery,
  MarkNotificationsAsReadMutation,
  OrderSubtasksMutation,
  RefineTaskInput,
  RefineTaskMutation,
  SetTaskStatusInput,
  SetTaskStatusMutation,
  SimilarTasksQuery,
  SimilarTasksQueryVariables,
  TaskMessagesQuery,
  UpdateTaskInput,
  UpdateTaskMutation,
  FetchNotesQuery,
  UpdateNoteInput,
  UpdateNoteMutation,
  CreateNoteInput,
  CreateNoteMutation,
  FetchAgentsQuery,
  CreateAgentInput,
  CreateAgentMutation,
  UpdateAgentInput,
  UpdateAgentMutation,
} from '@/gql/generated/graphql'
import { getApiHost } from '@/lib/getApiHost'

export interface Api {
  applyTaskRefinement: (
    refinementId: string
  ) => Promise<ApplyTaskRefinementMutation>
  createMessage: (
    input: CreateMessageInput
  ) => Promise<CreateMessageMutation['createMessage']>
  createTask: (
    taskInput: CreateTaskInput
  ) => Promise<CreateTaskMutation['createTask']>
  deleteTask: (taskId: string) => Promise<DeleteTaskMutation>
  deleteNotifications: (
    notificationIds: string[]
  ) => Promise<DeleteNotificationsMutation>
  fetchAllUsers: () => Promise<FetchAllUsersQuery>
  fetchAllWork: () => Promise<FetchAllTasksQuery['tasks']>
  fetchSimilarTasks: (
    input: SimilarTasksQueryVariables
  ) => Promise<SimilarTasksQuery>
  fetchTaskThreadEvents: (
    taskIds: string
  ) => Promise<GetThreadEventsQuery['tasks']>
  fetchTaskMessages: (taskId: string) => Promise<TaskMessagesQuery>
  fetchTasksById: (taskIds: string[]) => Promise<FetchTasksByIdQuery['tasks']>
  graphQLClient: GraphQLClient
  markNotificationsAsRead: (
    notificationIds: string[]
  ) => Promise<MarkNotificationsAsReadMutation>
  orderSubtasks: (
    orderedSubtaskIds: string[],
    insertAfterSubtaskWithId?: string
  ) => Promise<OrderSubtasksMutation>
  refineTask: (input: RefineTaskInput) => Promise<RefineTaskMutation>
  setTaskStatus: (input: SetTaskStatusInput) => Promise<SetTaskStatusMutation>
  updateTask: (input: UpdateTaskInput) => Promise<UpdateTaskMutation>
  updateNote: (input: UpdateNoteInput) => Promise<UpdateNoteMutation>
  createNote: (input: CreateNoteInput) => Promise<CreateNoteMutation>
  fetchViewer: () => Promise<FetchViewerQuery>
  fetchViewerWithTasks: () => Promise<FetchViewerWithTasksQuery>
  fetchViewerWithNotifications: () => Promise<FetchViewerWithNotificationsQuery>
  fetchActivityFlowData: (taskId: string) => Promise<FetchActivityFlowDataQuery>
  fetchNotes: () => Promise<FetchNotesQuery>
  getUploadFileParameters: (parameters: { files: File[] }) => Promise<{
    body: FormData
    headers: { Authorization: string }
    method: string
    url: string
  }>
  deleteFile: (fileId: string) => Promise<DeleteFileMutation>
  fetchAgents: () => Promise<FetchAgentsQuery>
  createAgent: (input: CreateAgentInput) => Promise<CreateAgentMutation>
  updateAgent: (input: UpdateAgentInput) => Promise<UpdateAgentMutation>
}

const ApiContext = createContext<Api | undefined>(undefined)
ApiContext.displayName = 'ApiContext'

export const useApi = () => {
  const context = useContext(ApiContext)
  if (!context) throw new Error('useApi must be used within a ApiProvider')
  return context
}

export const createTaskQueryKey = (taskId: string) => ['task', taskId]

export const ApiProvider: FC<PropsWithChildren> = ({ children }) => {
  const { getUser } = useOIDCAuth()

  const graphQLClient = useMemo(
    () =>
      new GraphQLClient(`${getApiHost()}/api/v1/graphql`, {
        fetch: async (url, options) => {
          const user = await getUser()
          if (!user) {
            return await fetch(url, options)
          }

          const headers = {
            ...options?.headers,
            Authorization: `Bearer ${user.access_token}`,
            'Content-Type': 'application/json',
          }

          return await fetch(url, { ...options, headers })
        },
      }),
    [getUser]
  )

  const applyTaskRefinement: Api['applyTaskRefinement'] = useCallback(
    async (refinementId) => {
      return await graphQLClient.request(
        graphql(`
          mutation applyTaskRefinement($refinementId: ID!) {
            applyTaskRefinement(input: { id: $refinementId }) {
              id
            }
          }
        `),
        {
          refinementId,
        }
      )
    },
    [graphQLClient]
  )

  const createMessage: Api['createMessage'] = useCallback(
    async (input) => {
      const response = await graphQLClient.request(
        graphql(`
          mutation CreateMessage($input: CreateMessageInput!) {
            createMessage(input: $input) {
              id
              createdAt
              author {
                id
                name
              }
              body {
                text
              }
            }
          }
        `),
        {
          input,
        }
      )

      return response.createMessage
    },
    [graphQLClient]
  )

  const createTask: Api['createTask'] = useCallback(
    async (taskInput) => {
      const response = await graphQLClient.request(
        graphql(`
          mutation CreateTask($taskInput: CreateTaskInput!) {
            createTask(input: $taskInput) {
              id
              title
              createdAt
              status
            }
          }
        `),
        {
          taskInput,
        }
      )

      return response.createTask
    },
    [graphQLClient]
  )

  const deleteTask: Api['deleteTask'] = useCallback(
    async (taskId: string) => {
      return await graphQLClient.request(
        graphql(`
          mutation DeleteTask($taskId: ID!) {
            deleteTask(id: $taskId)
          }
        `),
        {
          taskId,
        }
      )
    },
    [graphQLClient]
  )

  const deleteNotifications: Api['deleteNotifications'] = useCallback(
    async (ids: string[]) => {
      return await graphQLClient.request(
        graphql(`
          mutation DeleteNotifications($ids: [ID!]!) {
            deleteNotifications(ids: $ids)
          }
        `),
        {
          ids,
        }
      )
    },
    [graphQLClient]
  )

  const fetchAllUsers: Api['fetchAllUsers'] = useCallback(async () => {
    return await graphQLClient.request(
      graphql(`
        query FetchAllUsers {
          users {
            id
            name
            avatarUrl
          }
        }
      `),
      {}
    )
  }, [graphQLClient])

  const fetchAllWork: Api['fetchAllWork'] = useCallback(async () => {
    const response = await graphQLClient.request(
      graphql(`
        query FetchAllTasks {
          tasks {
            id
            title
            createdAt
            completedAt
            status
            dueAt
            assignee {
              id
              name
              avatarUrl
            }
          }
        }
      `)
    )
    return response.tasks
  }, [graphQLClient])

  const fetchSimilarTasks: Api['fetchSimilarTasks'] = useCallback(
    async (input) => {
      return await graphQLClient.request(
        graphql(`
          query SimilarTasks(
            $taskId: ID!
            $minSimilarityScore: Float
            $limit: Int
          ) {
            similarTasks(
              taskId: $taskId
              minSimilarityScore: $minSimilarityScore
              limit: $limit
            ) {
              task {
                id
                title
                description
                completedAt
                status
                assignee {
                  id
                  name
                  avatarUrl
                }
              }
            }
          }
        `),
        {
          limit: input.limit,
          minSimilarityScore: input.minSimilarityScore,
          taskId: input.taskId,
        }
      )
    },
    [graphQLClient]
  )

  const fetchTaskMessages: Api['fetchTaskMessages'] = useCallback(
    async (taskId) => {
      return await graphQLClient.request(
        graphql(`
          query TaskMessages($taskId: ID!) {
            tasks(ids: [$taskId]) {
              thread {
                id
                messages {
                  id
                  createdAt
                  author {
                    id
                    name
                    avatarUrl
                  }
                  body {
                    text
                  }
                  type
                  task {
                    title
                    description
                  }
                }
              }
            }
          }
        `),
        {
          taskId,
        }
      )
    },
    [graphQLClient]
  )

  const fetchTaskThreadEvents: Api['fetchTaskThreadEvents'] = useCallback(
    async (taskId) => {
      const response = await graphQLClient.request(
        graphql(`
          query GetThreadEvents($taskId: ID!) {
            tasks(ids: [$taskId]) {
              thread {
                id
                events {
                  id
                  createdAt
                  payload {
                    __typename
                    ... on Message {
                      id
                      author {
                        avatarUrl
                        id
                        name
                      }
                      body {
                        text
                      }
                    }
                    ... on TaskUpdate {
                      id
                      actor {
                        avatarUrl
                        id
                        name
                      }
                      affectedTask {
                        id
                        description
                        title
                        status
                      }
                      type
                    }
                    ... on FileMetadata {
                      name
                      fileUrl
                    }
                  }
                }
              }
            }
          }
        `),
        { taskId }
      )
      return response.tasks
    },
    [graphQLClient]
  )

  const fetchTasksById: Api['fetchTasksById'] = useCallback(
    async (taskIds) => {
      const response = await graphQLClient.request(
        graphql(`
          query FetchTasksById($taskIds: [ID!]!) {
            tasks(ids: $taskIds) {
              id
              title
              createdAt
              completedAt
              status
              dueAt
              description
              assignee {
                id
                name
                avatarUrl
              }
              thread {
                id
                messages {
                  id
                  createdAt
                  author {
                    id
                    name
                    avatarUrl
                  }
                  body {
                    text
                  }
                  type
                  task {
                    title
                    description
                  }
                }
              }
              subtasks {
                id
                title
                description
                createdAt
                completedAt
                status
                assignee {
                  id
                  name
                  avatarUrl
                }
              }
              parentTask {
                id
                title
                completedAt
                status
                parentTask {
                  id
                }
              }
            }
          }
        `),
        {
          taskIds,
        }
      )

      return response.tasks
    },
    [graphQLClient]
  )

  const markNotificationsAsRead: Api['markNotificationsAsRead'] = useCallback(
    async (notificationIds) => {
      return await graphQLClient.request(
        graphql(`
          mutation MarkNotificationsAsRead($notificationIds: [ID!]!) {
            markNotificationsAsRead(ids: $notificationIds) {
              id
              isUnread
            }
          }
        `),
        {
          notificationIds,
        }
      )
    },
    [graphQLClient]
  )

  const orderSubtasks: Api['orderSubtasks'] = useCallback(
    async (orderedSubtaskIds, insertAfterSubtaskWithId) => {
      return await graphQLClient.request(
        graphql(`
          mutation OrderSubtasks(
            $orderedSubtaskIds: [ID!]!
            $insertAfterSubtaskWithId: ID
          ) {
            orderSubtasks(
              input: {
                orderedSubtaskIds: $orderedSubtaskIds
                insertAfterSubtaskWithId: $insertAfterSubtaskWithId
              }
            ) {
              subtasks {
                id
              }
            }
          }
        `),
        {
          ...(insertAfterSubtaskWithId && { insertAfterSubtaskWithId }),
          orderedSubtaskIds,
        }
      )
    },
    [graphQLClient]
  )

  const refineTask: Api['refineTask'] = useCallback(
    async ({ precedentTaskIds, previousTaskRefinementFeedback, taskId }) => {
      return await graphQLClient.request(
        graphql(`
          mutation refineTask(
            $taskId: ID!
            $precedentTaskIds: [ID!]
            $previousTaskRefinementFeedback: PreviousTaskRefinementFeedbackInput
          ) {
            refineTask(
              input: {
                taskId: $taskId
                precedentTaskIds: $precedentTaskIds
                previousTaskRefinementFeedback: $previousTaskRefinementFeedback
              }
            ) {
              id
              updates {
                change {
                  parentTaskId
                  title
                }
              }
              precedentTasks {
                assignee {
                  name
                  avatarUrl
                }
                id
                title
                completedAt
                createdAt
              }
            }
          }
        `),
        {
          precedentTaskIds,
          previousTaskRefinementFeedback,
          taskId,
        }
      )
    },
    [graphQLClient]
  )

  const setTaskStatus: Api['setTaskStatus'] = useCallback(
    async (input) => {
      return await graphQLClient.request(
        graphql(`
          mutation SetTaskStatus($id: ID!, $status: TaskStatus!) {
            setTaskStatus(input: { id: $id, status: $status }) {
              id
              status
            }
          }
        `),
        {
          id: input.id,
          status: input.status,
        }
      )
    },
    [graphQLClient]
  )

  const updateNote = useCallback(
    async (input: UpdateNoteInput) => {
      return await graphQLClient.request(
        graphql(`
          mutation UpdateNote($id: ID!, $title: String, $body: String) {
            updateNote(input: { id: $id, title: $title, body: $body }) {
              id
              title
              body
            }
          }
        `),
        {
          body: input.body,
          id: input.id,
          title: input.title,
        }
      )
    },
    [graphQLClient]
  )

  const createNote = useCallback(
    async (input: CreateNoteInput) => {
      return await graphQLClient.request(
        graphql(`
          mutation CreateNote($title: String!, $body: String) {
            createNote(input: { title: $title, body: $body }) {
              id
              title
              body
            }
          }
        `),
        {
          body: input.body,
          title: input.title,
        }
      )
    },
    [graphQLClient]
  )

  const updateTask: Api['updateTask'] = useCallback(
    async (input: UpdateTaskInput) => {
      return await graphQLClient.request(
        graphql(`
          mutation UpdateTask(
            $id: ID!
            $title: String
            $description: String
            $assigneeId: ID
            $dueAt: DateTime
            $parentTaskId: ID
          ) {
            updateTask(
              input: {
                id: $id
                title: $title
                description: $description
                assigneeId: $assigneeId
                dueAt: $dueAt
                parentTaskId: $parentTaskId
              }
            ) {
              id
              title
              description
              assignee {
                id
                name
                avatarUrl
              }
              dueAt
              status
            }
          }
        `),
        {
          assigneeId: input.assigneeId,
          description: input.description,
          dueAt: input.dueAt,
          id: input.id,
          parentTaskId: input.parentTaskId,
          title: input.title,
        }
      )
    },
    [graphQLClient]
  )

  const fetchViewer: Api['fetchViewer'] = useCallback(async () => {
    return await graphQLClient.request(
      graphql(`
        query FetchViewer {
          viewer {
            id
            name
            avatarUrl
          }
        }
      `)
    )
  }, [graphQLClient])

  const fetchViewerWithTasks: Api['fetchViewerWithTasks'] =
    useCallback(async () => {
      return await graphQLClient.request(
        graphql(`
          query FetchViewerWithTasks {
            viewer {
              id
              name
              avatarUrl
              tasks {
                id
                title
                description
                completedAt
                status
                parentTask {
                  id
                  title
                }
              }
            }
          }
        `)
      )
    }, [graphQLClient])

  const fetchViewerWithNotifications: Api['fetchViewerWithNotifications'] =
    useCallback(async () => {
      return await graphQLClient.request(
        graphql(`
          query FetchViewerWithNotifications {
            viewer {
              id
              name
              avatarUrl
              notifications {
                id
                createdAt
                isUnread
                sourceMessage {
                  id
                  createdAt
                  author {
                    id
                    name
                    avatarUrl
                  }
                  body {
                    text
                  }
                }
                task {
                  id
                }
              }
            }
          }
        `)
      )
    }, [graphQLClient])

  const fetchActivityFlowData: Api['fetchActivityFlowData'] = useCallback(
    async (taskId) => {
      return await graphQLClient.request(
        graphql(`
          query FetchActivityFlowData($taskId: ID!) {
            getTaskActivityFlowData(taskId: $taskId) {
              activities {
                id
              }
              nodeId
              parentId
              adjacentNodes {
                nodeId
                traceCount
              }
              activityCount
              summary
            }
          }
        `),
        {
          taskId,
        }
      )
    },
    [graphQLClient]
  )

  const fetchNotes: Api['fetchNotes'] = useCallback(async () => {
    return await graphQLClient.request(
      graphql(`
        query FetchNotes {
          notes {
            id
            body
            title
          }
        }
      `)
    )
  }, [graphQLClient])

  const getUploadFileParameters: Api['getUploadFileParameters'] = useCallback(
    async ({ files }) => {
      const file = files?.[0]

      if (!file) {
        throw new Error('No file provided')
      }

      const formData = new FormData()

      // Add the operations part
      formData.append(
        'operations',
        JSON.stringify({
          query:
            'mutation($file: Upload!){ uploadFile(file: $file) { id, fileUrl } }',
          // eslint-disable-next-line unicorn/no-null
          variables: { file: null },
        })
      )

      // Add the map part
      formData.append(
        'map',
        JSON.stringify({
          file: ['variables.file'],
        })
      )

      formData.append('file', file)
      const user = await getUser()
      if (!user) {
        throw new Error('User not found')
      }
      const headers = {
        Authorization: `Bearer ${user.access_token}`,
      }

      return {
        body: formData,
        headers,
        method: 'POST',
        url: `${getApiHost()}/api/v1/graphql`,
      }
    },
    [getUser]
  )

  const deleteFile: Api['deleteFile'] = useCallback(
    async (id: string) => {
      return await graphQLClient.request(
        graphql(`
          mutation DeleteFile($id: ID!) {
            deleteFile(id: $id)
          }
        `),
        { id }
      )
    },
    [graphQLClient]
  )

  const fetchAgents: Api['fetchAgents'] = useCallback(async () => {
    return await graphQLClient.request(
      graphql(`
        query FetchAgents {
          agents {
            id
            name
            avatarUrl
            behavioralInstructions
          }
        }
      `)
    )
  }, [graphQLClient])

  const createAgent: Api['createAgent'] = useCallback(
    async (input: CreateAgentInput) => {
      return await graphQLClient.request(
        graphql(`
          mutation CreateAgent(
            $name: String!
            $behavioralInstructions: String
            $avatarUrl: String
          ) {
            createAgent(
              input: {
                name: $name
                avatarUrl: $avatarUrl
                behavioralInstructions: $behavioralInstructions
              }
            ) {
              id
              name
              avatarUrl
              behavioralInstructions
            }
          }
        `),
        {
          avatarUrl: input.avatarUrl,
          behavioralInstructions: input.behavioralInstructions,
          name: input.name,
        }
      )
    },
    [graphQLClient]
  )

  const updateAgent: Api['updateAgent'] = useCallback(
    async (input: UpdateAgentInput) => {
      return await graphQLClient.request(
        graphql(`
          mutation UpdateAgent(
            $id: ID!
            $name: String
            $behavioralInstructions: String
            $avatarUrl: String
          ) {
            updateAgent(
              input: {
                id: $id
                name: $name
                avatarUrl: $avatarUrl
                behavioralInstructions: $behavioralInstructions
              }
            ) {
              id
              name
              avatarUrl
              behavioralInstructions
            }
          }
        `),
        {
          avatarUrl: input.avatarUrl,
          behavioralInstructions: input.behavioralInstructions,
          id: input.id,
          name: input.name,
        }
      )
    },
    [graphQLClient]
  )

  return (
    <ApiContext.Provider
      value={{
        applyTaskRefinement,
        createAgent,
        createMessage,
        createNote,
        createTask,
        deleteFile,
        deleteNotifications,
        deleteTask,
        fetchActivityFlowData,
        fetchAgents,
        fetchAllUsers,
        fetchAllWork,
        fetchNotes,
        fetchSimilarTasks,
        fetchTaskMessages,
        fetchTaskThreadEvents,
        fetchTasksById,
        fetchViewer,
        fetchViewerWithNotifications,
        fetchViewerWithTasks,
        getUploadFileParameters,
        graphQLClient,
        markNotificationsAsRead,
        orderSubtasks,
        refineTask,
        setTaskStatus,
        updateAgent,
        updateNote,
        updateTask,
      }}
    >
      {children}
    </ApiContext.Provider>
  )
}
ApiProvider.displayName = 'ApiProvider'
