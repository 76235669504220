import { FC } from 'react'

import { StackCardBodyProperties } from '@/components/layout/Stack'
import { NewNote } from '@/features/library/components/NewNote'
import { NoteBody } from '@/features/library/components/NoteBody'
import { NoteTitle } from '@/features/library/components/NoteTitle'

export const NotesStackCardBody: FC<StackCardBodyProperties> = ({ id }) => {
  if (id === 'new') {
    return <NewNote />
  }

  return (
    <>
      <NoteTitle id={id} className="border-b border-film-normal p-0 px-3" />
      <div className="overflow-y-auto pb-7">
        <NoteBody id={id} className="mx-15 mt-7" />
      </div>
    </>
  )
}

NotesStackCardBody.displayName = 'NotesStackCardBody'
