import { FC } from 'react'

import { StackCardBodyProperties } from '@/components/layout/Stack'
import { AgentForm } from '@/features/team/components/AgentForm'
import { UpdateAgent } from '@/features/team/components/UpdateAgent'

export const AgentsStackCardBody: FC<StackCardBodyProperties> = ({ id }) => {
  if (id === 'new') {
    return <AgentForm />
  }

  return <UpdateAgent id={id} />
}

AgentsStackCardBody.displayName = 'AgentsStackCardBody'
