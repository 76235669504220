import { FileMetadata } from '@/gql/generated/graphql'
export type Headers = Record<string, string>

export interface RequestOptions {
  method: string
  url: string
  headers?: Headers
  body: Document | BodyInit | null | undefined
}

interface BeforeRequestProperties {
  xhr: XMLHttpRequest
  files: File[]
}
export type BeforeRequest = (
  properties: BeforeRequestProperties
) => Promise<RequestOptions | undefined> | RequestOptions | undefined

export interface UploadProperties {
  files: File[]
}

export interface UploadState {
  loading: boolean
  done: boolean
  data?: FileMetadata
  error?: ProgressEvent
  xhr?: XMLHttpRequest
  responseHeaders?: Headers
  progress?: number
}

export type UploadFunction = (data: UploadProperties) => any

export interface FileUploadCreatorProperties {
  beforeRequest: BeforeRequest
  setState: (data: UploadState) => any
  updateState: (callback: (data: UploadState) => UploadState) => any
}

export interface FileUploadResponse {
  data: { uploadFile: FileMetadata }
}

export const fileUploadCreator = ({
  beforeRequest,
  setState,
  updateState,
}: FileUploadCreatorProperties): UploadFunction => {
  const upload = async ({ files }: UploadProperties) => {
    setState({ done: false, loading: true })

    const xhr = new XMLHttpRequest()
    const options = await beforeRequest({ files, xhr })
    // bail out if you return undefined from options
    if (!options) return setState({ done: false, loading: false })
    xhr.open(options.method, options.url)

    /*
      Helper method for setting headers on an xhr request, one of the only
      extra features of this hook
    */
    if (options.headers) {
      const headers = options.headers
      for (const header of Object.keys(options.headers))
        xhr.setRequestHeader(header, String(headers[header]))
    }

    /*
      XHR Listeners
    */
    xhr.upload.addEventListener('progress', (event) => {
      updateState((state) => ({
        ...state,
        progress: Math.round((event.loaded / event.total) * 100),
      }))
    })

    xhr.addEventListener('load', () => {
      let data: FileMetadata
      try {
        data = (
          JSON.parse(
            typeof xhr.response === 'string'
              ? xhr.response
              : String(xhr.response)
          ) as FileUploadResponse
        ).data.uploadFile
      } catch {
        data = (xhr.response as FileUploadResponse).data.uploadFile
      }

      const headerLines = xhr
        .getAllResponseHeaders()
        .trim()
        .split(/[\r\n]+/)
        .map((line) => line.split(': '))

      const responseHeaders: Headers = {}

      for (const [header, value] of headerLines) {
        if (header && value) {
          responseHeaders[header] = value
        }
      }

      setState({ data, done: true, loading: false, responseHeaders, xhr })
    })

    xhr.addEventListener('error', (error) => {
      setState({ done: true, error, loading: false, xhr })
    })

    xhr.addEventListener('abort', (error) => {
      setState({ done: true, error, loading: false, xhr })
    })

    /*
      send the request!
    */
    xhr.send(options.body as XMLHttpRequestBodyInit | null)
  }

  return upload
}
