import * as AvatarPrimitive from '@radix-ui/react-avatar'
import { FC, ReactNode, useMemo } from 'react'

import { DefaultFallbackAvatarIcon } from '@/components/ui/avatar/DefaultFallbackAvatarIcon'
import { Tooltip } from '@/components/ui/tooltip/Tooltip'
import { IconComponent } from '@/lib/types'
import { cn } from '@/lib/utils'
interface Properties extends Omit<AvatarPrimitive.AvatarImageProps, 'src'> {
  tooltip?: ReactNode
  src?: string | null
  testId?: string
  FallbackAvatarIcon?: IconComponent
}

export const buildAvatarUrl = (avatarUrl?: string) => {
  const host =
    import.meta.env.PUBLIC_API_HOST ??
    `${globalThis.location.protocol}//${globalThis.location.host}`

  return `${host}${avatarUrl}`
}

export const Avatar: FC<Properties> = ({
  FallbackAvatarIcon = DefaultFallbackAvatarIcon,
  className,
  src,
  testId,
  tooltip,
  ...properties
}) => {
  const avatarComponent = useMemo(
    () => (
      <AvatarPrimitive.Root
        data-testid={testId ?? 'avatar-image'}
        className="flex items-center"
      >
        <AvatarPrimitive.Image
          src={src ? buildAvatarUrl(src) : ''}
          className={cn('object-cover', className)}
          {...properties}
        />
        <AvatarPrimitive.Fallback>
          <FallbackAvatarIcon className={className} />
        </AvatarPrimitive.Fallback>
      </AvatarPrimitive.Root>
    ),
    [className, FallbackAvatarIcon, properties, src, testId]
  )

  if (tooltip) {
    return <Tooltip content={tooltip}>{avatarComponent}</Tooltip>
  }

  return avatarComponent
}

Avatar.displayName = 'Avatar'
