import * as Tabs from '@radix-ui/react-tabs'
import { FC, useMemo } from 'react'

import { SmartSuspense } from '@/components/SmartSuspense'
import { ProcessPathIcon } from '@/components/icons/ProcessPathIcon'
import { RadarIcon } from '@/components/icons/RadarIcon'
import { ThreadIcon } from '@/components/icons/ThreadIcon'
import { Button } from '@/components/ui/button/Button'
import { EditableTitleSkeleton } from '@/components/ui/editable-title/EditableTitleSkeleton'
import {
  TaskTitle,
  TaskTitleProperties,
} from '@/features/task/components/TaskTitle'
import { useTask } from '@/features/task/hooks/useTask'
import { IconComponent } from '@/lib/types'
import { TASK_DETAILS_TABS } from '@/routes/_private/all-work.$taskId'

export type TaskTabValue = (typeof TASK_DETAILS_TABS)[number]

const TAB_TRIGGERS: Array<{
  dataTestId: string
  value: TaskTabValue
  text: string
  Icon: IconComponent
}> = [
  {
    Icon: ThreadIcon,
    dataTestId: 'thread-tab',
    text: 'Thread',
    value: 'thread',
  },
  {
    Icon: ProcessPathIcon,
    dataTestId: 'flowchart-tab',
    text: 'Flow Chart',
    value: 'flowchart',
  },
  {
    Icon: RadarIcon,
    dataTestId: 'relevant-tab',
    text: 'Relevant',
    value: 'relevant',
  },
]

interface TaskHeaderProperties extends Omit<TaskTitleProperties, 'task'> {
  hideTabs?: boolean
  isFlowChartEnabled: boolean
  taskId: string
}

const TaskHeader: FC<TaskHeaderProperties> = ({
  hideTabs = false,
  isFlowChartEnabled,
  onSubmit,
  taskId,
}) => {
  const { task } = useTask(taskId)

  return (
    <div className="relative flex flex-row flex-wrap items-end justify-between gap-2 border-b border-film-subtle px-4">
      <TaskTitle onSubmit={onSubmit} task={task} />
      {!hideTabs && (
        <Tabs.List className="mb-5 flex gap-1">
          {TAB_TRIGGERS.map(
            ({ Icon, dataTestId, text, value }) =>
              (isFlowChartEnabled || value !== 'flowchart') && (
                <Tabs.Trigger key={value} value={value} asChild>
                  <Button
                    size="sm"
                    className="bg-transparent text-mono-ink-subtle [&[data-state='active']]:bg-film-normal [&[data-state='active']]:text-mono-ink-strong"
                    data-testid={dataTestId}
                  >
                    <Icon />
                    {text}
                  </Button>
                </Tabs.Trigger>
              )
          )}
        </Tabs.List>
      )}
    </div>
  )
}

TaskHeader.displayName = 'TaskHeader'

const SuspendedTaskHeader = ({
  hideTabs = false,
  isFlowChartEnabled,
  onSubmit,
  taskId,
}: TaskHeaderProperties) => {
  return (
    <SmartSuspense
      fallback={useMemo(
        () => (
          <EditableTitleSkeleton />
        ),
        []
      )}
      fallbackMinDurationMs={300}
    >
      <TaskHeader
        isFlowChartEnabled={isFlowChartEnabled}
        onSubmit={onSubmit}
        taskId={taskId}
        hideTabs={hideTabs}
      />
    </SmartSuspense>
  )
}

SuspendedTaskHeader.displayName = 'SuspendedTaskHeader'

export { SuspendedTaskHeader as TaskHeader }
