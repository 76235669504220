import { IconComponent } from '@/lib/types'

export const NotesStickyIcon: IconComponent = ({ ...properties }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...properties}
  >
    <path
      d="M5.25 3C4.01563 3 3 4.01563 3 5.25V18.75C3 19.9844 4.01563 21 5.25 21H14.75C14.9479 21 15.1406 20.9219 15.2812 20.7812C15.2839 20.7786 15.2865 20.776 15.2891 20.7734L20.7812 15.2812C20.9219 15.1406 21 14.9479 21 14.75V5.25C21 4.01563 19.9844 3 18.75 3H5.25ZM5.25 4.5H18.75C19.1745 4.5 19.5 4.82552 19.5 5.25V14H16.25C15.0156 14 14 15.0156 14 16.25V19.5H5.25C4.82552 19.5 4.5 19.1745 4.5 18.75V5.25C4.5 4.82552 4.82552 4.5 5.25 4.5ZM7.75 7C7.47917 6.99479 7.22917 7.13802 7.09115 7.36979C6.95573 7.60417 6.95573 7.89323 7.09115 8.1276C7.22917 8.36198 7.47917 8.5026 7.75 8.5H16.25C16.5208 8.5026 16.7708 8.36198 16.9089 8.1276C17.0443 7.89323 17.0443 7.60417 16.9089 7.36979C16.7708 7.13802 16.5208 6.99479 16.25 7H7.75ZM7.75 10.5C7.47917 10.4948 7.22917 10.638 7.09115 10.8698C6.95573 11.1042 6.95573 11.3932 7.09115 11.6276C7.22917 11.862 7.47917 12.0026 7.75 12H14.25C14.5208 12.0026 14.7708 11.862 14.9089 11.6276C15.0443 11.3932 15.0443 11.1042 14.9089 10.8698C14.7708 10.638 14.5208 10.4948 14.25 10.5H7.75ZM16.25 15.5H18.4401L15.5 18.4401V16.25C15.5 15.8255 15.8255 15.5 16.25 15.5Z"
      fill="currentColor"
    />
  </svg>
)

NotesStickyIcon.displayName = 'NotesStickyIcon'
