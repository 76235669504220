import { useCallback, useState } from 'react'

import { BeforeRequest, fileUploadCreator, UploadState } from '@/lib/fileUpload'

export const useFileUpload = (beforeRequest: BeforeRequest) => {
  const [filesState, setFilesState] = useState<Record<string, UploadState>>({})

  const upload = useCallback(
    (files: File[]) => {
      for (const file of files) {
        const fileUpload = fileUploadCreator({
          beforeRequest,
          setState: (state) => {
            setFilesState((previous) => ({
              ...previous,
              [file.name]: state,
            }))
          },
          updateState: (updateState) => {
            setFilesState((previousFilesState) => {
              const previousFileState = previousFilesState[file.name]

              return previousFileState
                ? {
                    ...previousFilesState,
                    [file.name]: updateState(previousFileState),
                  }
                : previousFilesState
            })
          },
        })

        fileUpload({ files: [file] })
      }
    },
    [beforeRequest, setFilesState]
  )

  return [upload, filesState, setFilesState] as const
}
