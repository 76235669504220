import { useFlag } from '@unleash/proxy-client-react'
import React, { useCallback } from 'react'
import { createPortal } from 'react-dom'

import { CheckedCircleSolidIcon } from '@/components/icons/CheckedCircleSolidIcon'
import { CircleCrossFilledIcon } from '@/components/icons/CircleCrossFilledIcon'
import { PlusMathIcon } from '@/components/icons/PlusMathIcon'
import { Avatar } from '@/components/ui/avatar/Avatar'
import { Tooltip } from '@/components/ui/tooltip/Tooltip'
import { UnassignedIcon } from '@/features/task/components/UnassignedIcon'
import { ChatMessage } from '@/features/task/components/chat/ChatMessage'
import { ChatMessageInputWithNewMessages } from '@/features/task/components/chat/ChatMessageInputWithNewMessages'
import { CircleIcon } from '@/features/task/components/chat/CircleIcon'
import { useChat } from '@/features/task/components/chat/useChat'
import { useUsers } from '@/features/task/hooks/useUsers'
import {
  FetchAllUsersQuery,
  FetchTasksByIdQuery,
  FetchViewerQuery,
  GetThreadEventsQuery,
  MessageType,
  TaskUpdateType,
} from '@/gql/generated/graphql'
import { getApiHost } from '@/lib/getApiHost'
import { useViewer } from '@/lib/hooks/useViewer'
import { cn, formatMessageTimestamp } from '@/lib/utils'

interface ThreadStatus {
  icon: React.ReactNode
  text: string
}

const messageTypeIcons: Record<MessageType, React.ReactNode> = {
  MESSAGE: undefined,
  TASK_CREATED: <PlusMathIcon className="h-4 w-4 text-white" />,
  TASK_STATUS_CANCELLED: <CircleCrossFilledIcon className="text-white" />,
  TASK_STATUS_COMPLETED: <CheckedCircleSolidIcon className="text-white" />,
  TASK_STATUS_OPENED: <CircleIcon />,
}

const threadStatus: Record<TaskUpdateType, ThreadStatus> = {
  CREATED: {
    icon: <PlusMathIcon className="h-4 w-4 text-white" />,
    text: 'created task',
  },
  STATUS_CANCELLED: {
    icon: <CircleCrossFilledIcon className="text-white" />,
    text: 'marked task as cancelled',
  },
  STATUS_COMPLETED: {
    icon: <CheckedCircleSolidIcon className="text-white" />,
    text: 'marked task as complete',
  },
  STATUS_OPENED: { icon: <CircleIcon />, text: 'marked task as open' },
}

export type TasksQueryTask = FetchTasksByIdQuery['tasks'][number]
type Users = FetchAllUsersQuery['users']

const ChatViewDefaultThread = ({
  newMessageCallback,
  previousMessageCallback,
  taskMessages,
  users,
  viewer,
}: {
  newMessageCallback: (node: HTMLDivElement) => void
  previousMessageCallback: (node: HTMLDivElement) => void
  taskMessages: TasksQueryTask['thread']['messages']
  viewer: FetchViewerQuery['viewer']
  users: Users
}) => {
  return (
    <>
      {taskMessages.map((message, index) => {
        const isNewMessage = taskMessages.length === index + 1
        const isPreviousMessage = taskMessages.length - 2 === index
        const localizedDate = new Date(message.createdAt).toLocaleString()

        return (
          <div
            key={message.id}
            data-testid={`message-${message.id}`}
            className="flex gap-3 bg-none"
            ref={
              isNewMessage
                ? newMessageCallback
                : isPreviousMessage
                  ? previousMessageCallback
                  : undefined
            }
          >
            <Avatar
              alt={message.author?.name ?? viewer.name}
              src={message.author?.avatarUrl ?? viewer.avatarUrl}
              className="size-6 min-h-6 min-w-6 rounded-full"
              FallbackAvatarIcon={UnassignedIcon}
            />

            <div
              className={cn('flex flex-col items-start gap-2', {
                truncate: message.type !== 'MESSAGE',
              })}
            >
              <div className="flex items-center gap-2">
                <p className="text-md-bold text-mono-ink-strong">
                  {message.author?.name ?? viewer?.name ?? 'Odin'}
                </p>
                {message.type !== 'MESSAGE' && (
                  <>
                    <p className="text-sm-regular text-mono-ink-subtle">
                      {message.body.text}
                    </p>
                    <div className="h-0.75 w-0.75 rounded-full bg-film-strongest" />
                  </>
                )}

                <Tooltip
                  content={localizedDate}
                  side="top"
                  data-testid={`message-timestamp-tooltip-${message.id}`}
                >
                  <span
                    className="text-xs-regular text-film-strongest"
                    data-testid={`message-timestamp-${message.id}`}
                  >
                    {formatMessageTimestamp(message.createdAt)}
                  </span>
                </Tooltip>
              </div>

              {message.type === 'MESSAGE' ? (
                <p
                  className="min-w-1 whitespace-pre-wrap rounded-lg border bg-film-subtle p-3"
                  data-testid={`message-text-${message.id}`}
                >
                  <ChatMessage message={message.body.text} users={users} />
                </p>
              ) : (
                <div className="flex w-fit max-w-full items-center gap-1 truncate rounded-[66px] border border-film-faint bg-[#ECE8E1] p-1.5">
                  <div className="flex h-8 w-8 shrink-0 items-center justify-center rounded-full bg-gold p-1">
                    {messageTypeIcons[message.type]}
                  </div>
                  <div className="flex w-full flex-col truncate">
                    <p className="w-full truncate text-sm font-medium text-[#694C2F]">
                      {message.task?.title}
                    </p>
                    {message.task?.description && (
                      <p className="w-full truncate text-xs-regular text-mono-ink-subtle">
                        {message.task?.description}
                      </p>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        )
      })}
    </>
  )
}
ChatViewDefaultThread.displayName = 'ChatViewDefaultThread'

const ChatViewEventsThread = ({
  newMessageCallback,
  previousMessageCallback,
  threadEvents,
  users,
  viewer,
}: {
  newMessageCallback: (node: HTMLDivElement) => void
  previousMessageCallback: (node: HTMLDivElement) => void
  threadEvents: GetThreadEventsQuery['tasks'][number]['thread']['events']
  viewer: FetchViewerQuery['viewer']
  users: Users
}) => {
  return (
    <>
      {threadEvents.map((event, index) => {
        const isNewMessage = threadEvents.length === index + 1
        const isPreviousMessage = threadEvents.length - 2 === index
        const localizedDate = new Date(event.createdAt).toLocaleString()

        const fileMetadata = event.payload.filter(
          (payload) => payload.__typename === 'FileMetadata'
        )
        const message = event.payload.find(
          (payload) => payload.__typename === 'Message'
        )
        const taskUpdate = event.payload.find(
          (payload) => payload.__typename === 'TaskUpdate'
        )

        return (
          <div
            key={event.id}
            data-testid={`message-${event.id}`}
            ref={
              isNewMessage
                ? newMessageCallback
                : isPreviousMessage
                  ? previousMessageCallback
                  : undefined
            }
          >
            <div className="flex items-center gap-4">
              <Avatar
                alt={
                  message?.author?.name ??
                  taskUpdate?.actor?.name ??
                  viewer.name
                }
                src={
                  message?.author?.avatarUrl ??
                  taskUpdate?.actor?.avatarUrl ??
                  viewer.avatarUrl
                }
                className="size-6 min-h-6 min-w-6 rounded-full"
                FallbackAvatarIcon={UnassignedIcon}
              />

              <div className="flex items-center gap-2 truncate">
                <p className="text-md-bold text-mono-ink-strong">
                  {message?.author?.name ??
                    taskUpdate?.actor?.name ??
                    viewer?.name ??
                    'Odin'}
                </p>

                {taskUpdate && (
                  <>
                    <p className="text-sm-regular text-mono-ink-subtle">
                      {threadStatus[taskUpdate.type].text}
                    </p>
                    <div className="h-0.75 w-0.75 rounded-full bg-film-strongest" />
                  </>
                )}

                <Tooltip
                  content={localizedDate}
                  side="top"
                  data-testid={`message-timestamp-tooltip-${message?.id}`}
                >
                  <span
                    className="text-xs-regular text-film-strongest"
                    data-testid={`message-timestamp-${message?.id}`}
                  >
                    {formatMessageTimestamp(event.createdAt)}
                  </span>
                </Tooltip>
              </div>
            </div>

            <div
              className={cn('ml-10 mt-1 w-fit', {
                'rounded-lg border border-film-faint bg-film-subtle p-2':
                  !taskUpdate,
              })}
            >
              {!!fileMetadata && (
                <div
                  className={cn('mb-1', {
                    'grid grid-cols-[repeat(auto-fit,_minmax(120px,_1fr))] gap-2':
                      fileMetadata.length > 1,
                  })}
                >
                  {fileMetadata.map((file, index) => (
                    <img
                      key={file.fileUrl + index}
                      src={`${getApiHost() + file.fileUrl}`}
                      alt={file.name}
                      className={cn('rounded-md object-cover', {
                        'w-[200px]': fileMetadata.length === 1,
                        'w-full': fileMetadata.length > 1,
                      })}
                    />
                  ))}
                </div>
              )}

              {!!message && message.body.text && (
                <div className="flex gap-3 bg-none">
                  <div className="flex flex-col items-start gap-2 truncate">
                    <p className="min-w-1 whitespace-pre-wrap">
                      <ChatMessage message={message.body.text} users={users} />
                    </p>
                  </div>
                </div>
              )}

              {!!taskUpdate && (
                <div className="flex w-fit max-w-full items-center gap-1 truncate rounded-[66px] bg-[#ECE8E1] p-1.5 pr-3">
                  <div className="flex h-8 w-8 shrink-0 items-center justify-center rounded-full bg-gold p-1">
                    {threadStatus[taskUpdate.type].icon}
                  </div>

                  <div className="flex w-full flex-col truncate">
                    <p className="w-full truncate text-sm font-medium text-[#694C2F]">
                      {taskUpdate.affectedTask.title}
                    </p>
                    {!!taskUpdate.affectedTask?.description && (
                      <p className="w-full truncate text-xs-regular text-mono-ink-subtle">
                        {taskUpdate.affectedTask.description}
                      </p>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        )
      })}
    </>
  )
}
ChatViewEventsThread.displayName = 'ChatViewEventsThread'

export const ChatView = ({
  isRenderedInStackCard,
  taskFooterElement,
  taskId,
}: {
  taskId: string
  isRenderedInStackCard: boolean
  taskFooterElement: HTMLDivElement | null | undefined
}) => {
  const fileUploadEnabled = useFlag('pro-1247-ability-to-post-images-in-thread')
  const { viewer } = useViewer()
  const { users } = useUsers()

  const {
    createNewMessage,
    handleScrollToNewMessage,
    hasNewReceivedMessage,
    isCreatingNewMessage,
    newMessageCallback,
    previousMessageCallback,
    taskMessages,
    taskMessagesThread,
    thread,
    threadEvents,
  } = useChat<HTMLDivElement>(taskId)

  const handleSendMessage = useCallback(
    (data: { message: string; fileIds?: string[] }) => {
      // consolidate for different string format from react mention
      const formatMentions = (text: string) => {
        const mentionRegex = /@\[(.+?)\]\((.+?)\)/g
        // eslint-disable-next-line unicorn/prefer-string-replace-all
        return text.replace(mentionRegex, '<@U$2> ')
      }

      void (async () => {
        if (fileUploadEnabled && thread?.thread.id) {
          await createNewMessage({
            body: {
              fileIds: data.fileIds,
              text: formatMentions(data.message),
            },
            threadId: thread.thread.id,
          })
          return
        }

        if (taskMessagesThread?.id) {
          await createNewMessage({
            body: {
              text: formatMentions(data.message),
            },
            threadId: taskMessagesThread.id,
          })
        }
      })()
    },
    [fileUploadEnabled, createNewMessage, taskMessagesThread, thread]
  )

  const handleScrollToNewMessageSync = useCallback(() => {
    handleScrollToNewMessage()
  }, [handleScrollToNewMessage])

  return (
    <div className="flex flex-1 flex-col justify-end overflow-hidden">
      <div className="flex flex-col gap-6 overflow-y-auto pb-6">
        {fileUploadEnabled ? (
          <ChatViewEventsThread
            newMessageCallback={newMessageCallback}
            previousMessageCallback={previousMessageCallback}
            threadEvents={threadEvents}
            users={users}
            viewer={viewer}
          />
        ) : (
          <ChatViewDefaultThread
            newMessageCallback={newMessageCallback}
            previousMessageCallback={previousMessageCallback}
            taskMessages={taskMessages}
            users={users}
            viewer={viewer}
          />
        )}
      </div>

      {isRenderedInStackCard && taskFooterElement ? (
        createPortal(
          <ChatMessageInputWithNewMessages
            onNewMessagesButtonClick={handleScrollToNewMessageSync}
            onSendMessage={handleSendMessage}
            disabled={isCreatingNewMessage}
            hasNewReceivedMessage={hasNewReceivedMessage}
          />,
          taskFooterElement
        )
      ) : (
        <ChatMessageInputWithNewMessages
          onNewMessagesButtonClick={handleScrollToNewMessageSync}
          onSendMessage={handleSendMessage}
          disabled={isCreatingNewMessage}
          hasNewReceivedMessage={hasNewReceivedMessage}
        />
      )}
    </div>
  )
}

ChatView.displayName = 'ChatView'
