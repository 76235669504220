import { IconComponent } from '@/lib/types'

export const BotRobotMachineIcon: IconComponent = ({ ...properties }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...properties}
    >
      <path
        d="M11.987 1.98932C11.5755 1.99453 11.2448 2.33567 11.25 2.74974V3.49974H7.75C6.51563 3.49974 5.5 4.51536 5.5 5.74974V10.2497C5.5 11.4841 6.51563 12.4997 7.75 12.4997H16.25C17.4844 12.4997 18.5 11.4841 18.5 10.2497V5.74974C18.5 4.51536 17.4844 3.49974 16.25 3.49974H12.75V2.74974C12.7526 2.54661 12.6745 2.3513 12.5312 2.20807C12.3854 2.06484 12.1901 1.98672 11.987 1.98932ZM7.75 4.99974H16.25C16.6745 4.99974 17 5.32526 17 5.74974V10.2497C17 10.6742 16.6745 10.9997 16.25 10.9997H7.75C7.32552 10.9997 7 10.6742 7 10.2497V5.74974C7 5.32526 7.32552 4.99974 7.75 4.99974ZM9.75 6.49974C9.0599 6.49974 8.5 7.05963 8.5 7.74974C8.5 8.43984 9.0599 8.99974 9.75 8.99974C10.4401 8.99974 11 8.43984 11 7.74974C11 7.05963 10.4401 6.49974 9.75 6.49974ZM14.25 6.49974C13.5599 6.49974 13 7.05963 13 7.74974C13 8.43984 13.5599 8.99974 14.25 8.99974C14.9401 8.99974 15.5 8.43984 15.5 7.74974C15.5 7.05963 14.9401 6.49974 14.25 6.49974ZM6.25 13.9997C5.01563 13.9997 4 15.0154 4 16.2497V16.6326C4 17.8044 4.48177 18.9294 5.34375 19.7341C6.50521 20.8174 8.60938 21.9997 12 21.9997C15.3906 21.9997 17.4948 20.8174 18.6562 19.7341C19.5182 18.9294 20 17.8044 20 16.6299V16.2497C20 15.0154 18.9844 13.9997 17.75 13.9997H6.25ZM6.25 15.4997H17.75C18.1745 15.4997 18.5 15.8253 18.5 16.2497V16.6299C18.5 17.3956 18.1901 18.1195 17.6354 18.6378C16.7214 19.4867 15.0495 20.4997 12 20.4997C8.95052 20.4997 7.27865 19.4867 6.36458 18.6378C5.8125 18.1195 5.5 17.3956 5.5 16.6326V16.2497C5.5 15.8253 5.82552 15.4997 6.25 15.4997Z"
        fill="currentColor"
      />
    </svg>
  )
}

BotRobotMachineIcon.displayName = 'BotRobotMachineIcon'
