import { Link, useMatches, useRouter } from '@tanstack/react-router'
import { ComponentProps, FC, Fragment } from 'react'

import { ForwardSlash } from '@/components/icons/ForwardSlash'
import { IconComponent } from '@/lib/types'
import { cn } from '@/lib/utils'

const withBreadcrumbIconStyles = (Icon: IconComponent) => {
  const BreadcrumbIcon: FC<ComponentProps<typeof Icon>> = ({
    className,
    ...properties
  }) => <Icon className={cn('size-4', className)} {...properties} />
  BreadcrumbIcon.displayName = 'BreadcrumbIcon'
  return BreadcrumbIcon
}

export const Breadcrumbs: FC = () => {
  const { routesById } = useRouter()
  const matches = useMatches()

  const crumbObjects = matches
    .filter(({ routeId }) => {
      const route = routesById[routeId]
      const searchElement = route.id
      const isHomeRoute = searchElement === '/_private/'

      return (
        !['/_private', '__root__'].includes(searchElement) &&
        (route.path !== '/' || isHomeRoute)
      )
    })
    .map(({ routeId }) => {
      const route = routesById[routeId]
      const { Breadcrumb, NavigationIcon } = route.interloom ?? {}

      if (
        NavigationIcon &&
        routeId !== '/_private/' &&
        routeId !== '/_private/library' &&
        routeId !== '/_private/team'
      ) {
        return {
          Component: withBreadcrumbIconStyles(NavigationIcon),
          route,
        }
      }

      if (!Breadcrumb) {
        throw new Error(`RouteBreadcrumb for route ${route.id} not defined!`)
      }

      return {
        Component: Breadcrumb,
        route,
      }
    })

  if (crumbObjects.length === 0) {
    return
  }

  return (
    <div
      data-testid="breadcrumbs"
      className="flex h-15 flex-row items-center justify-between p-3 pl-4"
    >
      <div className="flex flex-row items-center space-x-2.5">
        {crumbObjects.map(({ Component, route }, index) => {
          const key = `breadcrumb-${route.fullPath}-${index}`
          if (index === crumbObjects.length - 1) {
            return <Component key={key} />
          }

          return (
            <Fragment key={key}>
              <Link
                className="opacity-50 hover:opacity-100"
                to={route.fullPath}
              >
                <Component />
              </Link>

              <ForwardSlash className="size-4 opacity-[0.18]" />
            </Fragment>
          )
        })}
      </div>
      <div id="breadcrumbsPortal"></div>
    </div>
  )
}
Breadcrumbs.displayName = 'Breadcrumbs'
