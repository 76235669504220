import { FC, useCallback } from 'react'
import { FormProvider, useForm, SubmitHandler, useWatch } from 'react-hook-form'
import TextareaAutosize from 'react-textarea-autosize'

import { Button } from '@/components/ui/button/Button'
import { InputField } from '@/components/ui/input-field/InputField'
import { FallbackAgentAvatarIcon } from '@/features/team/components/FallbackAgentAvatarIcon'
import { useCreateAgent } from '@/features/team/hooks/useCreateAgent'
import { useUpdateAgent } from '@/features/team/hooks/useUpdateAgent'
import { FetchAgentsQuery } from '@/gql/generated/graphql'
import { useCloseStackItem } from '@/lib/hooks/useCloseStackView'
import { CreateAgentInput } from '@/tests/generated-api-types'

interface AgentFormProperties {
  agent?: FetchAgentsQuery['agents'][number]
}

export const AgentForm: FC<AgentFormProperties> = ({ agent }) => {
  const methods = useForm<CreateAgentInput>({ defaultValues: agent })
  const { control, register } = methods
  const name = useWatch({ control, name: 'name' })
  const { closeStackItem } = useCloseStackItem()
  const { mutate: createAgentMutation } = useCreateAgent()
  const { mutate: updateAgentMutation } = useUpdateAgent()

  const closeForm = useCallback(() => {
    agent ? closeStackItem(agent.id, 'agent') : closeStackItem('new', 'agent')
  }, [agent, closeStackItem])

  const onSubmit: SubmitHandler<CreateAgentInput> = useCallback(
    (values) => {
      agent
        ? updateAgentMutation({ ...values, id: agent.id })
        : createAgentMutation(values)

      closeForm()
    },
    [agent, closeForm, createAgentMutation, updateAgentMutation]
  )

  return (
    <FormProvider {...methods}>
      <form
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={methods.handleSubmit(onSubmit)}
        onReset={closeForm}
        className="flex h-full flex-col justify-between overflow-y-auto text-mono-ink-fade"
      >
        <div>
          <div className="flex items-center gap-7 border-b border-film-normal px-7 py-8">
            <FallbackAgentAvatarIcon className="size-28 rounded-full" />
            <label
              className="flex-1 truncate text-4xl text-stone-700"
              data-testid="agent-name-title"
            >
              {name}
            </label>
          </div>
          <div className="mx-5 flex flex-col gap-2 border-b border-film-normal py-7">
            <label htmlFor="name" className="font-bold">
              Name
            </label>
            <InputField
              name="name"
              className="rounded-md bg-film-subtle p-2"
              placeholder="Enter agent name..."
              data-testid="agent-name-input"
            />
          </div>
          <div className="mx-5 flex flex-col gap-2 border-b border-film-normal py-7">
            <label htmlFor="description" className="font-bold">
              Job Description
            </label>
            <TextareaAutosize
              minRows={4}
              className="rounded-md bg-film-subtle p-2"
              {...register('behavioralInstructions')}
              placeholder="Enter agent job description..."
              data-testid="agent-description-input"
            />
          </div>
        </div>
        <div className="flex justify-end gap-7 bg-mono-paper p-5">
          <Button type="reset">{agent ? 'REVERT CHANGES' : 'CANCEL'}</Button>
          <Button type="submit" variant="solid" disabled={!name}>
            {agent ? 'SAVE AGENT' : 'CREATE AGENT'}
          </Button>
        </div>
      </form>
    </FormProvider>
  )
}

AgentForm.displayName = 'NewAgent'
