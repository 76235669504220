import { useMutation } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import { useApi } from '@/contexts/ApiProvider'
import { logger } from '@/lib/logger'

export const useDeleteFile = () => {
  const api = useApi()

  return useMutation({
    mutationFn: api.deleteFile,
    onError: (error) => {
      logger.error(error)
      toast.error(error.message)
    },
  })
}
