import {
  FC,
  useCallback,
  useRef,
  useState,
  FocusEvent,
  ChangeEvent,
} from 'react'

import { Button } from '@/components/ui/button/Button'
import { Form } from '@/components/ui/form/Form'
import {
  TextArea,
  TextAreaProperties,
} from '@/components/ui/text-area/TextArea'
import { useBoolean } from '@/lib/hooks/useBoolean'
import { cn } from '@/lib/utils'

interface SavableTextAreaProperties
  extends Pick<TextAreaProperties, 'placeholder' | 'minRows'> {
  onSave: (text?: string) => void
  text?: string
  placeholder?: string
  className?: string
  disableEmptySave?: boolean
}

export const SavableTextArea: FC<SavableTextAreaProperties> = ({
  className,
  disableEmptySave,
  onSave,
  text,
  ...rest
}) => {
  const { setFalse, setTrue, value } = useBoolean()
  const textAreaReference = useRef<HTMLTextAreaElement>(null)
  const [currentText, setCurrentText] = useState(text?.trim())
  const previousDescription = useRef<string | undefined>(text ?? undefined)

  const handleSave = useCallback(() => {
    if (disableEmptySave && !currentText) return

    setFalse()

    if (previousDescription.current === currentText) return

    onSave(currentText)
    previousDescription.current = currentText
    textAreaReference.current?.blur()
  }, [currentText, disableEmptySave, onSave, setFalse])

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      setCurrentText(event.target.value)
    },
    []
  )

  const handleReset = useCallback(() => {
    setCurrentText(previousDescription.current ?? '')
    setFalse()
    textAreaReference.current?.blur()
  }, [setFalse])

  const handleFocus = useCallback(
    (event: FocusEvent<HTMLFormElement>) => {
      if (event.target instanceof HTMLAnchorElement) return

      setTrue()
    },
    [setTrue]
  )

  return (
    <Form
      onSubmit={handleSave}
      onCmdEnterKey={handleSave}
      onClickAway={handleSave}
      onEscapeKey={handleReset}
      onFocus={handleFocus}
      onReset={handleReset}
      onBlur={handleSave}
      className={className}
    >
      <TextArea
        mode="controlled"
        data-testid="savable-textarea"
        name="description"
        onChange={handleChange}
        value={currentText ?? ''}
        ref={textAreaReference}
        focused={value}
        {...rest}
      />
      <div className={cn('flex justify-end gap-1 pt-2', { hidden: !value })}>
        <Button
          size="sm"
          type="reset"
          variant="naked"
          data-testid="button-discard-description"
          aria-hidden={!value}
        >
          Discard
        </Button>
        <Button
          size="sm"
          type="submit"
          variant="solid"
          data-testid="button-save-description"
          aria-hidden={!value}
          disabled={disableEmptySave && !currentText}
        >
          Save
        </Button>
      </div>
    </Form>
  )
}

SavableTextArea.displayName = 'SavableTextArea'
