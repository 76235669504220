import { useCallback, useMemo } from 'react'

import { EditableTitle } from '@/components/ui/editable-title/EditableTitle'
import { TaskIcon } from '@/features/task/components/TaskIcon'
import { FetchTasksByIdQuery, UpdateTaskInput } from '@/gql/generated/graphql'

export interface TaskTitleProperties {
  onSubmit: (data: Partial<UpdateTaskInput>) => void
  task: FetchTasksByIdQuery['tasks'][number]
}

export const TaskTitle = ({ onSubmit, task }: TaskTitleProperties) => {
  const handleSubmit = useCallback(
    (title: string) => {
      onSubmit({ id: task.id, title })
    },
    [onSubmit, task.id]
  )

  return (
    <EditableTitle
      icon={useMemo(
        () => (
          <TaskIcon status={task.status} />
        ),
        [task.status]
      )}
      label="Rename task"
      defaultTitle={task.title}
      titleOnSubmit={handleSubmit}
    />
  )
}

TaskTitle.displayName = 'TaskTitle'
