import { useMutation, useQueryClient } from '@tanstack/react-query'
import { ClientError } from 'graphql-request'
import isNil from 'lodash/isNil'
import omitBy from 'lodash/omitBy'
import { toast } from 'react-toastify'

import { useApi } from '@/contexts/ApiProvider'
import { FetchNotesQuery } from '@/gql/generated/graphql'
import { logger } from '@/lib/logger'

export const useUpdateNote = () => {
  const api = useApi()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: api.updateNote,
    onMutate: async (payload) => {
      const previousStates: Record<
        'note' | 'notes',
        FetchNotesQuery | undefined
      > = {
        note: undefined,
        notes: undefined,
      }

      for (const queryKey of [['note', payload.id], ['notes']] as const) {
        const previousState =
          queryClient.getQueryData<FetchNotesQuery>(queryKey)

        previousStates[queryKey[0]] = previousState

        queryClient.setQueryData<FetchNotesQuery>(queryKey, (previousState) => {
          if (!previousState) return

          const updatedNotes = previousState.notes.map((note) => {
            if (note.id === payload.id) {
              const payloadWithoutNilValues = omitBy(payload, isNil)

              return {
                ...note,
                ...payloadWithoutNilValues,
              }
            }

            return note
          })

          return {
            notes: updatedNotes,
          }
        })
      }

      return previousStates
    },
    // Partition: Important to keep these definitions below onMutate property so the TS compiler infers context properly
    onError: (error, variables, context) => {
      queryClient.setQueryData(['note', variables.id], context?.note)
      queryClient.setQueryData(['notes'], context?.notes)

      logger.error(error)

      if (error instanceof ClientError) {
        toast.error(error.response.errors?.[0]?.message)
      } else {
        toast.error('Note update failed!')
      }
    },
  })
}
