import { IconComponent } from '@/lib/types'

export const FallbackAgentAvatarIcon: IconComponent = ({ ...properties }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="44"
      fill="none"
      viewBox="0 0 44 44"
      {...properties}
    >
      <path fill="var(--vector-icon-border, #78716C)" d="M0 0h44v44H0z"></path>
      <path
        fill="var(--vector-icon-fill, #FAFAF9)"
        d="M21.987 11.99a.75.75 0 0 0-.737.76v.75h-3.5a2.26 2.26 0 0 0-2.25 2.25v4.5a2.26 2.26 0 0 0 2.25 2.25h8.5a2.26 2.26 0 0 0 2.25-2.25v-4.5a2.26 2.26 0 0 0-2.25-2.25h-3.5v-.75a.75.75 0 0 0-.763-.76M17.75 15h8.5c.424 0 .75.325.75.75v4.5c0 .424-.326.75-.75.75h-8.5a.737.737 0 0 1-.75-.75v-4.5c0-.425.326-.75.75-.75m2 1.5a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5m4.5 0a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5m-8 7.5A2.26 2.26 0 0 0 14 26.25v.383a4.24 4.24 0 0 0 1.344 3.101C16.505 30.817 18.609 32 22 32s5.495-1.183 6.656-2.266A4.24 4.24 0 0 0 30 26.63v-.38A2.26 2.26 0 0 0 27.75 24zm0 1.5h11.5c.424 0 .75.325.75.75v.38c0 .766-.31 1.49-.865 2.008-.914.849-2.586 1.862-5.635 1.862s-4.721-1.013-5.635-1.862a2.75 2.75 0 0 1-.865-2.005v-.383c0-.425.325-.75.75-.75"
      ></path>
    </svg>
  )
}

FallbackAgentAvatarIcon.displayName = 'FallbackAgentAvatarIcon'
