import { useCallback } from 'react'

import { SavableTextArea } from '@/components/ui/savable-text-area/SavableTextArea'
import { UpdateTaskInput } from '@/gql/generated/graphql'

interface TaskDescriptionProperties {
  onSave: (formData: Partial<UpdateTaskInput>) => void
  taskDescription?: string
}

export const TaskDescription = ({
  onSave,
  taskDescription,
}: TaskDescriptionProperties) => {
  const handleSave = useCallback(
    (description?: string) => {
      onSave({ description })
    },
    [onSave]
  )

  return (
    <SavableTextArea
      onSave={handleSave}
      text={taskDescription}
      placeholder="Add task details..."
    />
  )
}

TaskDescription.displayName = 'TaskDescription'
