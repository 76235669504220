import { useEffect } from 'react'

export const usePasteEvent = (
  callback: (event: ClipboardEvent) => void,
  isEnabled: boolean = true
) => {
  useEffect(() => {
    if (isEnabled) {
      document.addEventListener('paste', callback, true)

      return () => {
        document.removeEventListener('paste', callback, true)
      }
    }
  }, [callback, isEnabled])
}
