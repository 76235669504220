import { useSuspenseQuery } from '@tanstack/react-query'
import { RouteContext } from '@tanstack/react-router'

import { useApi } from '@/contexts/ApiProvider'
import { FetchAgentsQuery } from '@/gql/generated/graphql'

interface CreateQueryOptionParameters {
  api: RouteContext['api']
  id?: string
}

export const createAgentsQueryOptions = ({
  api,
  id,
}: CreateQueryOptionParameters) => {
  return {
    queryFn: api.fetchAgents,
    queryKey: id ? ['agent', id] : ['agents'],
  }
}

export function useAgents(): FetchAgentsQuery
export function useAgents(id: string): {
  agent: FetchAgentsQuery['agents'][number]
}
export function useAgents(id?: string) {
  const api = useApi()

  const queryOptions = createAgentsQueryOptions({ api, id })

  const { data } = useSuspenseQuery(queryOptions)

  if (id) {
    // Id filter is not supported by the server yet
    const agent = { agent: data.agents.find((agent) => agent.id === id) }
    if (!agent.agent) {
      throw new Error('Agent not found!')
    }

    return agent
  }

  return data
}
