import { useFlag } from '@unleash/proxy-client-react'
import React, {
  KeyboardEvent,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react'

import { AttachClipIcon } from '@/components/icons/AttachClipIcon'
import { MessageBubbleIcon } from '@/components/icons/MessageBubbleIcon'
import { SendIcon } from '@/components/icons/SendIcon'
import { Button } from '@/components/ui/button/Button'
import { TextAreaWithMentions } from '@/components/ui/input-with-mentions/TextAreaWithMentions'
import { useApi } from '@/contexts/ApiProvider'
import { ChatMessageInputFileList } from '@/features/task/components/chat/ChatMessageInputFileList'
import { useUsers } from '@/features/task/hooks/useUsers'
import { useFileUpload } from '@/lib/hooks/useFileUpload'

interface IChatMessageInputProperties {
  onSendMessage: (data: { message: string; fileIds?: string[] }) => void
  disabled?: boolean
}

const useChatMessageInput = ({
  onSendMessage,
}: IChatMessageInputProperties) => {
  const api = useApi()
  const [message, setMessage] = useState<string>('')
  const inputReference = useRef<HTMLTextAreaElement>(null)
  const [fileList, setFileList] = useState<File[]>([])

  const [upload, filesState, setFilesState] = useFileUpload(
    api.getUploadFileParameters
  )

  const isUploadPending = useMemo(
    () => fileList.some((file) => !filesState[file.name]?.done),
    [fileList, filesState]
  )

  const isSendingMessageDisabled = useMemo(
    () => (!message && fileList.length === 0) || isUploadPending,
    [message, fileList, isUploadPending]
  )

  const handleSendClick = useCallback(() => {
    if (!isSendingMessageDisabled) {
      const fileIds = Object.values(filesState)
        .map((state) => state.data?.id)
        .filter((id): id is string => !!id)

      onSendMessage({
        fileIds,
        message: message.trim(),
      })
      setMessage('')
      setFileList([])
      setFilesState({})
      if (inputReference.current) {
        inputReference.current.style.height = 'auto'
      }
    }
  }, [
    filesState,
    isSendingMessageDisabled,
    message,
    onSendMessage,
    setFileList,
    setFilesState,
  ])

  const handleKeyPress = useCallback(
    (event: KeyboardEvent<HTMLTextAreaElement | HTMLDivElement>) => {
      if (!isSendingMessageDisabled) {
        if (event.key === 'Escape') {
          event.preventDefault()
          setMessage('')
        }
        if (event.key === 'Enter' && !event.shiftKey) {
          event.preventDefault()
          handleSendClick()
        }
      }
    },
    [isSendingMessageDisabled, handleSendClick]
  )

  return {
    fileList,
    filesState,
    handleKeyPress,
    handleSendClick,
    isSendingMessageDisabled,
    isUploadPending,
    message,
    setFileList,
    setFilesState,
    setMessage,
    upload,
  }
}

const ChatMessageInputDefault: React.FC<IChatMessageInputProperties> = ({
  disabled = false,
  onSendMessage,
}) => {
  const { users } = useUsers()
  const { handleKeyPress, handleSendClick, message, setMessage } =
    useChatMessageInput({ onSendMessage })

  return (
    <div className="flex grow">
      <div className="flex grow flex-row items-end gap-3 rounded-lg bg-white p-3">
        <MessageBubbleIcon />
        <TextAreaWithMentions
          mentions={users.map((user) => ({
            avatarUrl: user.avatarUrl,
            display: user.name,
            id: user.id,
          }))}
          className="caret-sky-500"
          placeholder="Write a message..."
          onChange={setMessage}
          onKeyDown={handleKeyPress}
          value={message}
        />

        <Button
          data-testid="button-send-message"
          onClick={handleSendClick}
          size="sm"
          type="submit"
          variant="solid"
          disabled={!message || disabled}
        >
          <SendIcon />
        </Button>
      </div>
    </div>
  )
}

ChatMessageInputDefault.displayName = 'ChatMessageInputDefault'

const ChatMessageInputFileUpload: React.FC<IChatMessageInputProperties> = ({
  disabled = false,
  onSendMessage,
}) => {
  const { users } = useUsers()

  const {
    fileList,
    filesState,
    handleKeyPress,
    handleSendClick,
    isSendingMessageDisabled,
    isUploadPending,
    message,
    setFileList,
    setFilesState,
    setMessage,
    upload,
  } = useChatMessageInput({
    onSendMessage,
  })

  const handleFileUpload = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        const files = [...event.target.files].map(
          (file) => new File([file], `${file.name}-${Date.now()}`)
        )
        setFileList((previous) => [...previous, ...files])
        upload(files)
        event.target.value = ''
      }
    },
    [setFileList, upload]
  )

  const handlePaste = useCallback(
    (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
      const items = event.clipboardData?.items
      if (!items) return

      const imageItems = [...items].filter((item) =>
        item.type.startsWith('image/')
      )

      for (const item of imageItems) {
        const file = item.getAsFile()
        if (file) {
          const newFile = new File([file], `${file.name}-${Date.now()}`)
          setFileList((previous) => [...previous, newFile])
          upload([newFile])
        }
      }
    },
    [setFileList, upload]
  )

  return (
    <div className="rounded-lg bg-white py-3">
      {fileList.length > 0 && (
        <ChatMessageInputFileList
          fileList={fileList}
          filesState={filesState}
          setFileList={setFileList}
          setFilesState={setFilesState}
          onKeyPress={handleKeyPress}
        />
      )}

      <div className="px-3">
        <TextAreaWithMentions
          mentions={users.map((user) => ({
            avatarUrl: user.avatarUrl,
            display: user.name,
            id: user.id,
          }))}
          className="caret-sky-500"
          placeholder="Write a message..."
          onChange={setMessage}
          onKeyDown={handleKeyPress}
          value={message}
          onPaste={handlePaste}
        />

        <div className="flex flex-row items-center justify-end gap-3">
          <Button
            asChild
            variant="naked"
            size="sm"
            className="h-8 w-8 cursor-pointer p-0"
          >
            <label>
              <input
                data-testid="input-file-upload"
                type="file"
                className="hidden"
                onChange={handleFileUpload}
                multiple
                accept=".jpg,.jpeg,.png,.gif,.webp"
                disabled={isUploadPending}
              />
              <AttachClipIcon />
            </label>
          </Button>
          <Button
            data-testid="button-send-message"
            onClick={handleSendClick}
            size="sm"
            className="h-8 w-8 p-0"
            type="submit"
            variant="solid"
            disabled={isSendingMessageDisabled || disabled}
          >
            <SendIcon />
          </Button>
        </div>
      </div>
    </div>
  )
}

ChatMessageInputFileUpload.displayName = 'ChatMessageInputFileUpload'

export const ChatMessageInput = (properties: IChatMessageInputProperties) => {
  const fileUploadEnabled = useFlag('pro-1247-ability-to-post-images-in-thread')
  return fileUploadEnabled ? (
    <ChatMessageInputFileUpload {...properties} />
  ) : (
    <ChatMessageInputDefault {...properties} />
  )
}

ChatMessageInput.displayName = 'ChatMessageInput'
