import { ArrowDownIcon } from '@/components/icons/ArrowDownIcon'
import { Button } from '@/components/ui/button/Button'
import { ChatMessageInput } from '@/features/task/components/chat/ChatMessageInput'

interface IChatMessageInputWithMessageProperties {
  onSendMessage: (data: { message: string }) => void
  onNewMessagesButtonClick: () => void
  hasNewReceivedMessage?: boolean
  disabled?: boolean
}

export const ChatMessageInputWithNewMessages: React.FC<
  IChatMessageInputWithMessageProperties
> = ({
  disabled,
  hasNewReceivedMessage,
  onNewMessagesButtonClick,
  onSendMessage,
}) => {
  return (
    <div className="relative">
      {hasNewReceivedMessage && (
        <Button
          size="sm"
          variant="solid"
          type="button"
          className="absolute left-1/2 top-[-2.5rem] -translate-x-1/2 transform"
          onClick={onNewMessagesButtonClick}
        >
          <ArrowDownIcon />
          New messages
        </Button>
      )}

      <ChatMessageInput onSendMessage={onSendMessage} disabled={disabled} />
    </div>
  )
}

ChatMessageInputWithNewMessages.displayName = 'ChatMessageInputWithNewMessages'
