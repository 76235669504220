import { User, UserManager, WebStorageStateStore } from 'oidc-client-ts'
import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { ConfigurationVariables } from '@/lib/configurationVariablesSchemaValidator'
import { logger } from '@/lib/logger'
import { makeErrorFromUnknown } from '@/lib/utils'

export interface OidcAuthContextState {
  error?: Error
  loading: boolean
  signIn: () => Promise<void>
  signInCallback: () => Promise<void>
  signOut: () => Promise<void>
  user?: User
  getUser: () => Promise<User | undefined>
}

const OidcAuthContext = createContext<OidcAuthContextState | undefined>(
  undefined
)

OidcAuthContext.displayName = 'OidcAuthContext'

export const useOIDCAuth = () => {
  const context = useContext(OidcAuthContext)
  if (!context) {
    throw new Error('useOIDCAuth must be used within OidcAuthProvider')
  }

  return context
}

type InFlightDecorator<T extends unknown[]> = (
  ...arguments_: T
) => Promise<void>

interface Properties extends PropsWithChildren {
  configurationVariables: ConfigurationVariables
}

export const OidcAuthProvider: FC<Properties> = ({
  children,
  configurationVariables,
}) => {
  const [user, setUser] = useState<User>()
  const [networkOperationsInFlight, setNetworkOperationsInFlight] = useState(0)
  const [error, setError] = useState<Error>()
  const {
    PUBLIC_ZITADEL_CLIENT_ID,
    PUBLIC_ZITADEL_ISSUER_URL,
    PUBLIC_ZITADEL_ORGANIZATION_ID,
  } = configurationVariables.env

  const userManager = useMemo(() => {
    const redirectUri = `${globalThis.location.origin}/auth-callback`

    return new UserManager({
      authority: PUBLIC_ZITADEL_ISSUER_URL,
      automaticSilentRenew: true,
      client_id: PUBLIC_ZITADEL_CLIENT_ID,
      redirect_uri: redirectUri,
      scope: [
        'email',
        'openid',
        'profile',
        'offline_access',
        `urn:zitadel:iam:org:id:${PUBLIC_ZITADEL_ORGANIZATION_ID}`,
      ].join(' '),
      silent_redirect_uri: redirectUri,
      // By default, the lib. uses Session Storage
      userStore: new WebStorageStateStore({ store: localStorage }),
    })
  }, [
    PUBLIC_ZITADEL_CLIENT_ID,
    PUBLIC_ZITADEL_ISSUER_URL,
    PUBLIC_ZITADEL_ORGANIZATION_ID,
  ])

  const withInFlightStateUpdate =
    <T extends unknown[]>(function_: InFlightDecorator<T>) =>
    async (...arguments_: T) => {
      setNetworkOperationsInFlight((previousState) => previousState + 1)
      try {
        await function_(...arguments_)
      } catch (error) {
        setError(makeErrorFromUnknown(error))
      } finally {
        setNetworkOperationsInFlight((previousState) => previousState - 1)
      }
    }

  const loading = useMemo(
    () => networkOperationsInFlight > 0,
    [networkOperationsInFlight]
  )

  const isHydrated = useRef<boolean>(false)
  const initialize = useMemo(
    () =>
      withInFlightStateUpdate(async () => {
        setUser((await userManager.getUser()) ?? undefined)
        isHydrated.current = true
      }),
    [userManager]
  )

  useEffect(() => {
    if (loading || isHydrated.current) return
    void initialize()
  }, [initialize, loading])

  const signIn = useMemo(
    () =>
      withInFlightStateUpdate(async () => {
        await userManager.signinRedirect()
      }),
    [userManager]
  )

  const signOut = useMemo(
    () =>
      withInFlightStateUpdate(async () => {
        try {
          await userManager.removeUser()
        } catch (error) {
          logger.error(error)
        }

        globalThis.location.reload()
      }),
    [userManager]
  )

  const handleSilentRenew = useCallback(
    async (error: Error) => {
      logger.error(error)
      await signOut()
    },
    [signOut]
  )

  const signInCallbackInProgress = useRef(false)
  const signInCallback = useCallback(async () => {
    await withInFlightStateUpdate(async () => {
      if (signInCallbackInProgress.current) {
        return
      }
      signInCallbackInProgress.current = true

      try {
        await userManager.signinCallback()
      } catch (unknownError) {
        const error = makeErrorFromUnknown(unknownError)
        if (error.message === 'No matching state') {
          throw new Error('The code is not valid. Please try to sign in again.')
        }
        throw error
      } finally {
        signInCallbackInProgress.current = false
      }
    })()
  }, [userManager])

  useEffect(() => {
    const attachEventListeners = async () => {
      userManager.events.addSilentRenewError(handleSilentRenew)
      userManager.events.addUserLoaded(setUser)
    }

    void attachEventListeners()

    return () => {
      if (userManager) {
        userManager.events.removeSilentRenewError(handleSilentRenew)
        userManager.events.removeUserLoaded(setUser)
      }
    }
  }, [setUser, userManager, handleSilentRenew])

  const getUser = useCallback(async () => {
    const user = await userManager.getUser()
    if (!user) {
      return
    }

    if (user.expired) {
      try {
        return (await userManager.signinSilent()) ?? undefined
      } catch (maybeError) {
        console.error(maybeError)
        await signOut()
      }
      return
    }

    return user
  }, [userManager, signOut])

  useEffect(() => {
    if (user?.access_token && navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.postMessage({
        data: user?.access_token ?? '',
        type: 'SET_ACCESS_TOKEN',
      })
    }
  }, [user])

  return (
    <OidcAuthContext.Provider
      value={{
        error,
        getUser,
        loading,
        signIn,
        signInCallback,
        signOut,
        user,
      }}
    >
      {children}
    </OidcAuthContext.Provider>
  )
}
OidcAuthProvider.displayName = 'OidcAuthProvider'
