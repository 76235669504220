import { FC, useMemo } from 'react'

import { SmartSuspense } from '@/components/SmartSuspense'
import { Skeleton } from '@/components/ui/skeleton/Skeleton'
import { AgentForm } from '@/features/team/components/AgentForm'
import { useAgents } from '@/features/team/hooks/useAgents'

interface UpdateAgentProperties {
  id: string
}

const UpdateAgent: FC<UpdateAgentProperties> = ({ id }) => {
  const { agent } = useAgents(id)

  return <AgentForm agent={agent} />
}

UpdateAgent.displayName = 'UpdateAgent'

const SuspendedUpdateAgent: FC<UpdateAgentProperties> = (properties) => {
  return (
    <SmartSuspense
      fallback={useMemo(
        () => (
          <div
            className="flex h-full flex-col justify-between overflow-y-auto"
            data-testid="update-agent-skeleton"
          >
            <div>
              <div className="flex items-center gap-7 border-b border-film-normal px-7 py-8">
                <Skeleton className="size-28 rounded-full bg-film-subtle" />
                <Skeleton className="h-9 w-full flex-1 truncate bg-film-subtle" />
              </div>
              <div className="mx-5 flex flex-col gap-2 border-b border-film-normal py-7">
                <Skeleton className="h-6 w-24 bg-film-subtle" />
                <Skeleton className="h-10 w-full bg-film-subtle" />
              </div>
              <div className="mx-5 flex flex-col gap-2 border-b border-film-normal py-7">
                <Skeleton className="h-6 w-28 bg-film-subtle" />
                <Skeleton className="h-28 w-full bg-film-subtle" />
              </div>
            </div>
            <div className="flex justify-end gap-7 bg-mono-paper p-5">
              <Skeleton className="h-11 w-40 bg-film-subtle" />
              <Skeleton className="h-11 w-28 bg-film-subtle" />
            </div>
          </div>
        ),
        []
      )}
    >
      <UpdateAgent {...properties} />
    </SmartSuspense>
  )
}

SuspendedUpdateAgent.displayName = 'SuspendedUpdateAgent'

export { SuspendedUpdateAgent as UpdateAgent }
