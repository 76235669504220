import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import { FC, PropsWithChildren, ReactNode } from 'react'

interface Properties
  extends PropsWithChildren<
    Omit<TooltipPrimitive.TooltipContentProps, 'content'>
  > {
  content: ReactNode
}

export const Tooltip: FC<Properties> = ({
  children,
  content,
  ...properties
}) => {
  if (!content) {
    return children
  }

  return (
    <TooltipPrimitive.Provider delayDuration={50}>
      <TooltipPrimitive.Root>
        <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>

        <TooltipPrimitive.Portal>
          <TooltipPrimitive.Content
            forceMount
            sideOffset={4}
            align="center"
            side="top"
            className="z-40 animate-fadeIn rounded-sm bg-mono-paper px-2.5 py-1.5 text-sm-regular text-mono-ink-subtle shadow-micro data-[state=closed]:animate-fadeOut"
            {...properties}
          >
            {content}
            <TooltipPrimitive.Arrow
              offset={10}
              width={11}
              height={5}
              style={{
                clipPath: 'inset(0 -10px -10px -10px)', // Hide the shadow which overlaps the content above the arrow
              }}
              className="fill-mono-paper drop-shadow-micro filter"
            />
          </TooltipPrimitive.Content>
        </TooltipPrimitive.Portal>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  )
}

Tooltip.displayName = 'Tooltip'
