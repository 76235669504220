import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'react-toastify'

import { useApi } from '@/contexts/ApiProvider'
import { FetchTasksByIdQuery } from '@/gql/generated/graphql'
import { logger } from '@/lib/logger'

type Task = FetchTasksByIdQuery['tasks'][number]

export const useUpdateTaskStatus = (taskId: string, parentTaskId?: string) => {
  const api = useApi()
  const queryClient = useQueryClient()

  const taskQueryKey = ['task', taskId]
  const parentTaskQueryKey = ['task', parentTaskId]

  const taskStatusMutation = useMutation({
    mutationFn: api.setTaskStatus,
    onMutate: async ({ id, status }) => {
      const previousStateForTask = queryClient.getQueryData<Task>(taskQueryKey)
      queryClient.setQueryData<Task>(taskQueryKey, (previousState) => {
        if (!previousState) return

        return {
          ...previousState,
          status,
        }
      })

      queryClient.setQueryData<Task>(parentTaskQueryKey, (previousState) => {
        /* v8 ignore next */
        if (!previousState) return

        return {
          ...previousState,
          subtasks: previousState.subtasks.map((task) => {
            if (task.id === id) {
              return {
                ...task,
                status,
              }
            }

            return task
          }),
        }
      })

      return {
        previousState: previousStateForTask,
      }
    },
    // Partition: Important to keep these definitions below onMutate property so the TS compiler infers context properly
    onError: (error, _, context) => {
      queryClient.setQueryData(taskQueryKey, context?.previousState)
      logger.error(error)
      toast.error('Task update failed!')
    },
  })

  return {
    isUpdatingTaskStatus: taskStatusMutation.isPending,
    updateTaskStatus: taskStatusMutation.mutate,
  }
}
