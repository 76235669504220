const handleImageWorkerRegistration = () => {
  void navigator.serviceWorker.register(
    import.meta.env.MODE === 'production'
      ? '/serviceWorker.js'
      : '/dev-sw.js?dev-sw',
    { type: import.meta.env.MODE === 'production' ? 'classic' : 'module' }
  )
}

export const initialiseImageWorker = () => {
  if ('serviceWorker' in navigator) {
    if (navigator.serviceWorker.controller) {
      void navigator.serviceWorker
        .getRegistration(navigator.serviceWorker.controller.scriptURL)
        .then((worker) => {
          if (worker) {
            void worker.unregister().then(() => {
              handleImageWorkerRegistration()
            })
          }
        })
    } else {
      handleImageWorkerRegistration()
      globalThis.location.reload()
    }
  }
}

export class ImageWorker {
  private accessToken?: string
  private readonly imageUrl: string

  constructor(imageUrl: string) {
    this.imageUrl = imageUrl

    self.addEventListener('fetch', (event) => {
      this.onFetch(event as FetchEvent)
    })
    self.addEventListener('message', (event) => {
      this.onMessage(event)
    })
  }

  public getAccessToken(): string | undefined {
    return this.accessToken
  }

  public onFetch(event: FetchEvent): void {
    const url = new URL(event.request.url)

    if (url.pathname.startsWith(this.imageUrl)) {
      const modifiedRequest = new Request(event.request, {
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
        },
        mode: 'cors',
      })

      event.respondWith(fetch(modifiedRequest))
    }
  }

  public onMessage(event: MessageEvent): void {
    const { data, type } = event.data

    if (type === 'SET_ACCESS_TOKEN') {
      this.accessToken = data
    }
  }
}
